/* - BS3 variables - */

$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1179px;
$screen-lg-min: 1180px;
$screen-lg-max: 1600px;

$grid-breakpoints: (
  xs: 0,
  sm: $screen-sm-min,
  md: $screen-md-min,
  lg: $screen-lg-min,
);

$container-max-widths: (
  md: $screen-lg-min + 48px, // add padding
);

$container-extended-width: 1344px + 48px; // add padding
$nav-height-desktop: 130px;
$nav-height-desktop-fixed: 80px;
$nav-height-mobile: 80px;
