@import "variables";

.embed-item, .embed {
  @media (min-width: $screen-lg-min) {
    max-width: 900px;
  }
}

.sr-only {
    position: absolute;
    left: -999em;
    opacity: 0;
  }

.leaflet-bottom.leaflet-right {
  display: none!important;
}

.mi-full {
  margin-inline: 10px;

  @media (min-width: $screen-lg-min) {
    margin-inline: 300px;
  }
}
