@import "../../assets/stylesheets/variables";

.gridContainer {
  display: grid;
  gap: $base-gap;
  grid-template-columns: auto 1fr;
  padding: $base-gap;

  @media (min-width: $screen-md-min) {
    gap: $base-gap * 2;
    padding: $base-gap * 2 $base-gap * 1.5;
  }
}

.sectionContent {
  a {
    word-break: break-all;
    hyphens: auto;
    hyphenate-character: '';
    color: #007db1;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.sectionHeadline {
  font-weight: bold;
}

.contactWrap {
    display: flex;
    flex-direction: column;
    gap: $base-gap;
    list-style: none;
}

.contactTitle {
  display: block;
}

.container {
  background-color: $theme-graywhite;
  padding-inline: $base-gap * 4.5;

  .infoRow {
    padding-block: $base-gap;

    .label {
      font-size: $font-size-default-small;
      font-weight: 700;
      line-height: $line-height-default;
      color: $headline-color;
      margin-block-end: $base-gap * 0.5;
    }
  
    .details {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      font-size: $font-size-default-small;
      font-weight: 400;
      line-height: $line-height-default;
      color: $text-color;
      margin-block-end: $base-gap * 0.5;
      margin-inline: 0 !important;
      list-style-type: square;
      padding-block: 0;
      margin-block: 0;
      hyphens: auto;
      
      li {
        line-height: $line-height-default-large;
        margin-block: $base-gap * 0.5;
        margin-inline-start: $base-gap;
        padding: 0;
        padding-inline-start: $base-gap * 0.5;
        border-bottom: none;
        border-bottom:none;
        font-size: $font-size-default-small;
        font-weight: 400;

        &::marker {
          color: $headline-color;
        }
      } 

      svg {
        margin-inline-end: $base-gap * 0.5;
      }

      address {
        font-style: normal;
        margin: 0;
      }
    }
  }
  
  .contact {
    display: flex;
    flex-direction: row;

    > div {
      width: 50%;
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column;

      > div {
        width: 100%;
        &:first-child {
          margin-bottom: $base-gap;
        }
      }
    }
  }

  .hr {
    border: 1px solid $border-color;
    border-bottom: transparent;
    margin: 0;
  }
}
