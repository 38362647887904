@use "sass:math"; 
@import "../../assets/stylesheets/variables";
.locationMapFilter {
    .headlineWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
        }
    }

    .categoryFilterList {
        list-style: none;
        padding: 0;
        display: flex;
        gap: $base-gap $base-gap * 1.5;
        flex-wrap: wrap;
        display: none;
    }

    .upsideDown {
        transform: rotate(180deg);
    }

    .hide {
        display: none !important;
    }

    .categoryWrapper {
        position: relative;
        margin: 0;
    }

    .categoryCheckbox {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:hover {
            cursor: pointer;
        }

        &:focus-visible {
            outline: blue auto 1px;
        }
    }

    .categoryLabel {
        border: 0;
        border-radius: 0;
        padding: 0;
        display: flex;
        align-items: center;
        gap: $base-gap * 0.5;
        background-color: transparent;
        text-align: left;
    }

    .checkbox {
        height: 1.4em;
        width: 2.3em;
        background-color: #ebebeb;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 5000px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 2px;
        position: relative;
        border: 1px solid rgba(0,0,0,.2);
        flex-shrink: 0;

        &:before {
            content: '';
            height: 17px;
            width: 17px;
            background-color: currentColor;
            border-radius: 500px;
            border: 1px solid rgba(0,0,0,.25);
        }

        &.isActive {
            justify-content: flex-end;
            background-color: currentColor;

            &:before {
                background-color: white;
            }
        }
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .filterHeadline {
        padding: 0;
        margin: 0;
        font-family: $font-default;
        font-weight: 600;
        font-size: $font-size-default-large;
        line-height: $line-height-default-xlarge;
        margin-block-start: $base-gap * 2;
        margin-block-end: $base-gap * 0.5;
    }

    .filterWrapper {
        font-family: $font-default;
        display: flex;
        flex-direction: column;
        gap: $base-gap;
        width: 100%;

        .filterBlock {
            display: flex;
            flex-direction: column;
            padding: $base-gap;
            background: #E8F1FA;
            gap: $base-gap * 2;

            @media (min-width: $screen-lg-min) {
                flex-direction: row;
            }

            .filterFieldset {
                border: 0;
                margin: 0;
                padding: 0;
                height: auto;
                width: 50%;
                max-width: 460px;
                display: flex;
                flex-direction: column;
                
                @media (max-width: $screen-sm-max) {
                    width: 100%;
                }

                label {
                    font-weight: 700;
                    font-size: $font-size-default-small;
                    line-height: $line-height-default;
                    margin-bottom: $base-gap * 0.25;
                }

                & > input {
                    border-color: hsl(0, 0%, 70%);
                    background-color: hsl(0, 0%, 100%);
                    border-color: hsl(0, 0%, 80%);
                    border-radius: 0px;
                    border-style: solid;
                    border-width: 1px;
                    box-sizing: border-box;
                    min-height: 38px;
                    padding-inline: $base-gap * 0.5;
                    font-size: $font-size-default-small;
                }
                input {
                    border-radius: 0px !important;
                }

                .customMultiSelect {
                    > div {
                        border-radius: 0;
                    }
                    
                }
            } 
            
            &.filterBlockAccordion{
                background: transparent;
                padding: 0;
                margin: 0 !important;
                width: 100%;

                .accordion {
                    width: 100%;
                    

                    .accordionItem {
                        margin-block-end: $base-gap;
                    
                        .accordionHeading {
                            
                            // width: 100%;
                            
                            .accordionButton {
                                padding: $base-gap;
                                color: #333;
                                background-color: #F9F9F9;
                                border-color: #ddd;
                                border: 1px solid #d1e2f3;
                                font-size: $base-gap;
                                font-weight: 700;
                                line-height: 18px;
                                cursor: pointer;
                                
                                    &::before {
                                        content: " ";
                                        background-repeat: no-repeat;
                                        background-image: url(./icon-arrow-bottom.svg);
                                        width: 10px;
                                        height: 10px;
                                        padding-inline-start: $base-gap * 1.5;
                                        background-position-y: 40%;
                                    }
                                
                                &[aria-expanded=true] {
                                    background-color: #E8F1FA;
                                    &::before {
                                        content: " ";
                                        background-image: url(./icon-arrow-top.svg);
                                    }
                                }
                            }
                        }
                        .accordionPanel {
                            padding: $base-gap;
                            display: flex !important;
                            flex-direction: column;
                            gap: $base-gap * 2;
                            animation: fadein 0.35s ease-in;

                            @media (min-width: $screen-lg-min) {
                                flex-direction: row;
                            }
                        }
                    }
                }

            }
        }
        
    }

    .toggleWrap {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: $base-gap * 0.25;
        flex-grow: 1;
        flex-shrink: 0;

        @media (min-width: $screen-sm-min) {
            justify-content: flex-end;
        }
    }

    .toggleButton {
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        color: currentColor;
        margin-right: 0;
        padding: $base-gap * 0.5 $base-gap * 0.75;
        background-color: #ebebeb !important;

        &:disabled {
            color: white;
            background-color: #006397 !important;
        }

        &:hover:not(:disabled) {
        cursor: pointer;
        background: #dbdbdb !important;
        }
    }

    .buttonBox {
        display: flex;
        gap: $base-gap;
        justify-content: space-between;
        flex-direction: column;

        @media (min-width: $screen-md-min) {
            flex-direction: row;
        }

        .searchButton {
            width: 100%;
            border: 0px solid #313131;
            color: white;
            max-width: 400px;
            //margin-inline: auto;
            padding: $base-gap $base-gap * 1.5;
            background-color: #006397 !important;
            font-size: $font-size-default-small;
            font-weight: 600;
            line-height: $line-height-default;

            &:hover:not(:disabled) {
                cursor: pointer;
                background-color: #006397cc !important;
            }

            &[aria-disabled="true"] {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }

    .resetButton {
        border: none;
        padding-inline: $base-gap * 1;
        margin: $base-gap * 0.5;
        height: fit-content;
        font-size: $font-size-default-small;
        font-weight: 400;
        line-height: $line-height-default;
        color: white;
        background-color: #006397 !important;
        padding: $base-gap * 0.5;

        &:hover:not(:disabled) {
            cursor: pointer;
                background-color: #006397cc !important;
            }
    }

    .showFilterBtnWrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        .showFilterBtn {
            width:fit-content;
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            font-weight: 600;
            font-size: 16px;
            line-height: 22.4px;
            border: 1px solid hsl(0, 0%, 70%);
            padding: $base-gap;
            gap: $base-gap;
            cursor: pointer;

            &:hover, &:focus {
                background: hsl(0, 0%, 95%);
            }
        }
    }

    .sr-only {
        position: absolute;
        left: -999em;
        opacity: 0;
    }
}
