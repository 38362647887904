@use "sass:math"; 
@import "../../assets/stylesheets/variables";

$iconWidth:  $base-gap * 3.5;

.map {
  height: 100%;
  width:0;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  font-family: $font-default;

  @media (min-width: $screen-sm-min) {
    width: 100%;
  }
}

.mapContainer {
  position: relative;
  z-index: 2;
  max-height: 75vh;
  height: $screen-sm-min;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $screen-sm-min) {
    flex-wrap: nowrap;
    height: $screen-sm-min;
  }

  :global(.fslightbox-container.fslightbox-full-dimension) {
    top: $nav-height-desktop-fixed;
    height: calc(100% - #{$nav-height-desktop-fixed});
  }

  :global(.leaflet-layer) {
    &:first-child {
      // filter: grayscale(1);
      z-index: 3 !important;
    }
    &:nth-child(2) {
        filter: grayscale(1) opacity(0.6);
        z-index: 2 !important;
    }
    &:nth-child(3) {
      filter: grayscale(1) contrast(1) brightness(0)
    }
  }

  :global(.leaflet-div-icon) {
    background: transparent;
    border: none;
    }
}

.filterContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: $screen-sm-min) {
    flex-wrap: nowrap;
    height: $screen-sm-min;
    // max-width: 1200px;
  }
}

.customMarker {
  z-index: 1 !important;
}
.customMarkerWrapper {
  &:hover, &:focus {
    z-index: 10000 !important;

    > div > div {
      opacity: 1;
    }
  }
}

.activeMarker {
  > div {
    outline: 3px solid red !important;
  }
}

.invert {
  filter: invert(1);
}

.contentContainer {
  display: flex;
  width: $iconWidth;
  justify-content: space-between;
  align-items: center;
  margin:0;
}

.shadow {
  top: 200% !important;
}

:global(.marker-cluster-small div) {
  background-color: #1A76D0 !important;
  color: white;
}
:global(.marker-cluster-small) {
  background-color: #1A76D066 !important;
}

:global(.locationIndex) {
  ul {
    position: relative;
    min-width: 120px;
    width: fit-content;
    color: $text-color !important;
    list-style: none;
    margin: $base-gap;
    z-index: 400 !important;
    border-radius: 5px;
    margin: 0;
    margin-inline: auto;
    padding: 0;

    li {
      padding-block: $base-gap * 0.5;
      text-align: left;
      border-bottom: 1px solid $border-color;
      font-size: $font-size-default-xsmall;
      font-weight: 400;
      line-height: $line-height-default;

      &:last-child {
        border-bottom: none;
      }
    }

    &:hover {
      visibility: visible;
    }
  }
}

.customMarkerCluster {
  background-color: #1A76D066 !important;
  margin-left: -20px;
  margin-top: -20px;
  width: 40px !important;
  height: 40px !important;

  z-index: 2 !important;
  // transform: translateX(0px) !important;
  display: flex;
  opacity: 1;
  border-radius: 25px;

  > div {
    > div {
      position: relative;
      background-color: #1A76D0 !important;
      color: white;
      width: 30px;
      height: 30px;
      margin-left: 5px;
      margin-top: 5px;
      text-align: center;
      border-radius: 15px;
      z-index: 200 !important;
      span {
        line-height: 30px;
      }

    }
  }

  // &:hover {
  //   ul {
  //     visibility:visible;
  //   }
  // }
}

:global(.leaflet-popup-tip-container) {
  display: none !important;
}

:global(a.leaflet-popup-close-button) {
  display: none !important;
}

:global(.leaflet-popup-content-wrapper) {
  //pointer-events: none;
  position: absolute;
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateY(-45px) translateX(25px);
  background-color: white;
  color: $text-color;
  max-width: 300px;
  z-index: 4;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  .title {
    font-weight: 400;
    font-size: 14px; 
    line-height: 16.8px;
  }
  .description {
      font-weight: 400;
      font-size: 10px; 
      line-height: 12px;
      color: #797979;

  }

  :global(.leaflet-popup-content) {
    .btn {
      display: block;
      pointer-events: all;
      cursor: pointer;
      border-color: transparent;
      background: none;
      text-align: left;
      width: 100%;
      z-index: 5;

      &:hover {
        background-color: $theme-graylight;
      }
    }
  }
}

.hrPadding {
  margin: 8px 0;
}
