@import '../../assets/stylesheets/variables';

//Marker

.markerWrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    &.schule {
        //path {
        //    fill: $color-schule;
        //}

        //&.grundschule {
        //    path {
        //        fill: $color-schule-grundschule !important;
        //    }
        //}
        //&.oberschule {
        //    path {
        //        fill: $color-schule-oberschule !important;
        //    }
        //}
        //&.oberschule-plus {
        //    path {
        //        fill: $color-schule-oberschule-plus !important;
        //    }
        //}
        //&.gymnasium {
        //    path {
        //        fill: $color-schule-gymnasium !important;
        //    }
        //}
        //&.gemeinschaftsschule {
        //    path {
        //        fill: $color-schule-gemeinschaftsschule !important;
        //    }
        //}
        //&.schulgesetz {
        //    path {
        //        fill: $color-schule-schulgesetz !important;
        //    }
        //}
        //&.berufsbildende-schule {
        //    path {
        //        fill: $color-schule-berufsbildende-schule !important;
        //    }
        //}
        //&.berufsbildende-foederschule,
        //&.foerderschwerpunkt,
        //&.krankenhausschule {
        //    path {
        //        fill: $color-schule-berufsbildende-foederschule !important;
        //    }
        //}
        //&.zweiten-bildungsweges {
        //    path {
        //        fill: $color-schule-zweiten-bildungsweges !important;
        //    }
        //}
        //&.zweiten-bildungsweges {
        //    path {
        //        fill: $color-schule-ergaenzungsschule !important;
        //    }
        //}
    }
    &.kooperationsverbund {
        path {
            fill: $color-kooperationsverbund !important;
            // stroke: black !important;
        }
    }
    &.einrichtung {
        & > svg {
            path {
                fill: $color-einrichtung !important;
            }
        }

    }

    &.centered {
        bottom: 50%;
        transform: translate(-50%, 50%);
    }

    &:hover {
        .popover {
            opacity: 1;
        }
    }
}

.markerSvg {
    width: 48px;
    height: auto;
}

:global(.leaflet-container .leaflet-marker-pane) .imageWrapper { 
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 0;
    padding-bottom: 60%;
    display: flex;
    justify-content: center;
    align-items: center;

    img, svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        min-width: 80% !important;
    }

    .invert{
        filter: invert(1);
    }
}
