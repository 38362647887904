@import "../../assets/stylesheets/variables";

.item {
  border-bottom: 1px solid #e1e4e7;

  .itemButton {
    justify-content: unset;

    .arrow {
      padding-left: $base-gap;
    }
  }
}

.legend {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $base-gap;
  padding-top: $base-gap*0.5;
  padding-bottom: $base-gap;

  .element {
    position: relative;
    hyphens: auto;
    word-break: break-word;
    display: flex;
    flex-direction: row;
    gap: 0.5*$base-gap;
    max-width: 100%;
    width: 49%;
    height: 80px;

    &.fullWidth {
      max-width: 100%;
      width: 100%;
    }

    @media (min-width: $screen-lg-min) {
      width: 32%;
      max-width: 32%;
    }

    .customIcon {
      position: absolute;
      top: 22%;
      left: 8px;

      &:global(.lsj-sachsen-icon-36-34) {
        top: 15px;
        left: 6px;
      }
    }

    // koopv img ist kleiner als 32px deswegen muss die Ausrichtung angepasst werden
    img.customIcon {
      top: 26%;
      left: 9px;
    }

    .label {
      padding-left: $base-gap*0.5;
      margin: auto 0;
      max-width: 65%;
    }
  }
}
