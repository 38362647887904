/* colors
================================================== */

$primary: black !default;
$primaryUrified: "black" !default;

$secondary: #edf2f4 !default;
$theme-gray: #2e353c;
$theme-graydark: #656669;
$theme-graymedium: #b3b3b3;
$theme-graymiddle: #c6c6c6;
$theme-graylight: #edf2f4;
$theme-graywhite: #F9F9F9;
$border-color: #e1e4e7;


$text-color: #313131 !default;
$headline-color: #004E92;
$sub-headline-gray: #676B72 !default;
$table-border-color: #707070 !default;

$blue: #86c2eb !default;    // Abwasser
$green: #b7d69b !default;   // Natur und Gruen
$red: #f08372 !default;     // Strasse
$yellow: #ffe163 !default;  // Abfall
$orange: #fccb8d !default;  // TDB
$violet: #c693c2 !default;  // Neutral

$color-schule: #1A76D0;
$color-kooperationsverbund: #F29200;
$color-einrichtung: $green; //TODO: another color?!

$color-schule-grundschule: #f19156;
$color-schule-oberschule: #614918;
$color-schule-oberschule-plus: #614918;
$color-schule-gymnasium: #00782c;
$color-schule-gemeinschaftsschule: #750d68;
$color-schule-schulgesetz: #e600af;
$color-schule-berufsbildende-schule: #003788;
$color-schule-berufsbildende-foederschule: #c1002b;
$color-schule-foerderschwerpunkt: #c1002b;
$color-schule-krankenhausschule: #c1002b;
$color-schule-zweiten-bildungsweges: #989000;
$color-schule-ergaenzungsschule: #069893;


// -- validations -- //

$redBright: #c04e4e;
$orangeBright: #ff9f00;
$orangeDarken: #cc7f00;
$blueBright: #00addf;
$blueDarken: #0085ac;

$success: #0b6f49 !default;
$info: #5bc0de !default;
$warning: #f0ad4e !default;
$danger: #d60101 !default;

$nav-hover: #bbb;

$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee


$theme-colors: () !default;
$theme-colors: map-merge((
    "white": white,
    "primary": $primary,
    "secondary": $secondary,
    "theme-gray": $theme-gray,
    "theme-graydark": $theme-graydark,
    "theme-graymiddle": $theme-graymiddle,
    "theme-graymedium": $theme-graymedium,
    "theme-graylight": $theme-graylight,
    "theme-graywhite": $theme-graywhite,
    "border-color": $border-color,
    "text-color": $text-color,
    "sub-headline-gray": $sub-headline-gray,
    "table-border-color": $table-border-color,
    "blue": $blue,
    "green": $green,
    "red": $red,
    "yellow": $yellow,
    "orange": $orange,
    "violet": $violet,
    "redBright": $redBright,
    "orangeBright": $orangeBright,
    "orangeDarken": $orangeDarken,
    "blueBright": $blueBright,
    "blueDarken": $blueDarken,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "gray-base": $gray-base,
    "gray-darker": $gray-darker,
    "gray-dark": $gray-dark,
    "gray": $gray,
    "gray-light": $theme-graylight,
    "gray-lighter": $gray-lighter
), $theme-colors);


$btn-default-color: #333 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;

$btn-primary-color: #fff !default;
$btn-primary-bg: $primary !default;
$btn-primary-border: darken($btn-primary-bg, 5%) !default;

$btn-secondary-color: #fff !default;
$btn-secondary-bg: $secondary !default;
$btn-secondary-border: darken($btn-secondary-bg, 5%) !default;

$btn-success-color: #fff !default;
$btn-success-bg: $success !default;
$btn-success-border: darken($btn-success-bg, 5%) !default;

$btn-info-color: #fff !default;
$btn-info-bg: $info !default;
$btn-info-border: darken($btn-info-bg, 5%) !default;

$btn-warning-color: #fff !default;
$btn-warning-bg: $warning !default;
$btn-warning-border: darken($btn-warning-bg, 5%) !default;

$btn-danger-color: #fff !default;
$btn-danger-bg: $danger !default;
$btn-danger-border: darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color: $gray-light !default;

$input-border-focus: $btn-primary-color !default;

$scrivito-content-browser-tab-gray: rgb(232,232,232);
