@use "sass:math";
@import "../../assets/stylesheets/variables";

.popupWrapper {
  z-index: 1001;
  height: 100%;
  background: #fff;
  overflow-y: scroll;
  transition: 0.25s;
  visibility: hidden;
  opacity: 0;
  flex-shrink: 0;
  flex-grow: 0;
  width: 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-right: 0;
  border-color: $border-color;
  font-family: $font-default;

  &.opened {
    position: relative;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      width: 450px;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  position: sticky;
  top: 0;
  background: #fff;
  padding: $base-gap;
  z-index: 2;
  box-sizing: border-box;

  @media (min-width: $screen-sm-min) {
    border-bottom: $border-color 1px solid;
    padding: $base-gap * 1.5;
  }
}

.title {
  font-size: $font-size-default-large;
  font-weight: 700;
  line-height: $line-height-default-xlarge;
  flex-basis: 70%;
  flex-grow: 1;
  margin-bottom: 0;
  color: $headline-color;
  
  @media (min-width: $screen-sm-min) {
    font-size: 1.4em;
  }
}

h4.title {
  color: $text-color;
  font-size: $base-gap;
}

  .buttonContainer {
  .closeButton {
    width: 38px;
    height: 38px;
    background-color: #006397 !important;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    //background: transparent;
    color: currentColor;
    margin-right: 0;

    svg {
      color: white;
    }

    &:hover {
      cursor: pointer;
      background: #006fab !important;
    }
  }
}

.gridContainer {
  display: grid;
  gap: $base-gap;
  grid-template-columns: auto 1fr;
  padding: $base-gap;

  @media (min-width: $screen-md-min) {
    gap: $base-gap * 2;
    padding: $base-gap * 1.5;
  }
}

.sectionContent {
  a {
    word-break: break-all;
    hyphens: auto;
    hyphenate-character: '';
    color: #007db1;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.sectionHeadline {
    font-weight: bold;
}

.contactWrap {
    display: flex;
    flex-direction: column;
    gap: $base-gap;
    list-style: none;
}

.contactTitle {
  display: block;
}

.container {
  overflow-y: scroll;
  height: 528px;
  padding-inline: $base-gap * 1.5;

  .infoRow {
    padding-block: $base-gap * 0.75;

    &:first-child {
      padding-block-start: $base-gap * 1.25;
    }

    .label {
      font-size: $font-size-default-small;
      font-weight: 700;
      line-height: $line-height-default;
      color: $headline-color;
      margin-block-end: $base-gap * 0.5;
    }
  
    .details {
      display: flex;
      flex-direction: column;
      align-items: start;
      font-size: $font-size-default-small;
      font-weight: 400;
      line-height: $line-height-default;
      color: $text-color;
      margin-block-end: $base-gap * 0.5;
      list-style-type: square;
      padding-block: 0;
      margin-block: 0;
      margin-left: 0;
      z-index: 0 !important;

      li {
        border-bottom: none;
        line-height: $line-height-default-large;
        margin-block: $base-gap * 0.5;
        margin-inline-start: $base-gap;
        padding: 0;
        padding-inline-start: $base-gap * 0.5;
        font-size: $font-size-default-small;
        font-weight: 400;
        z-index: 0 !important;

        &::marker {
          color: $headline-color;
        }
      }
  
      svg {
        margin-inline-end: $base-gap * 0.5;
      }

      address {
        font-style: normal;
        margin: 0;
      }
    }
  }

  .hr {
    border: 1px solid $border-color;
    border-bottom: transparent;
    margin: 0;
  }
}
