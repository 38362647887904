@use "sass:math";
@import "../../assets/stylesheets/variables";

.headerWrapper {
    margin-top: $base-gap * 2;
    margin-bottom: $base-gap;
    display: flex;
    flex-wrap: wrap;
    gap: $base-gap * 1.5;
    align-items: flex-start;
    justify-content: space-between;
}

.headline {
    font-family: $font-default;
    font-size: 36px;
    font-weight: 600;
    line-height: $line-height-default-xlarge;
    color: $headline-color;
}
.subHeadline {
    font-weight: 400;
    display: block;
    margin-top: $base-gap * 0.25;
}

.infoline {
    display: flex;
    align-items:center;
    min-height: 33px;
    font-family: $font-default;
    font-size: $font-size-default-large;
    font-weight: 600;
    line-height: $line-height-default-xlarge;
    margin-block-end: $base-gap;
    margin-block-end: 0;
  }