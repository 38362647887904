@import "variables/colors";
@import "variables/responsive";
@import "variables/gaps";

/* Border-radius
================================================== */
$border-radius: 15px !default;
$border-radius-lg: 15px !default;
$border-radius-sm: 15px !default;

/* Box Shadow
================================================== */
$box-shadow-sm: 0px 3px 5px rgba(34, 50, 87, 0.12);
$box-shadow-md: 0px 8px 9px rgba(34, 50, 87, 0.12);
$box-shadow-lg: 0px 11px 15px rgba(34, 50, 87, 0.12);
$general-focus-box-shadow: 0 0 0 2px white,0 0 0 4px currentColor !important;

/* Fonts
================================================== */
$font-default: Arial, sans-serif;
$font-size-default: 18px;
$font-size-default-small: 16px;
$font-size-default-xsmall: 14px;
$font-size-default-large: 21px;

$line-height-default: 18px;
$line-height-default-small: 16px;
$line-height-default-large: 21px;
$line-height-default-xlarge: 33px;

/* Inputs
================================================== */
$input-border-color: #8F9499;
$input-border-width: 1px;
$input-border-radius: 5px;

$input-padding-y: 11px;
$input-padding-x: 16px;

$input-height: auto;
$custom-control-indicator-checked-bg: red;
$form-check-input-margin-y: .1em;