@use "sass:math";
@import "../../assets/stylesheets/variables";

.buttonsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    @media (min-width: $screen-sm-min) {
        flex-direction: row;
    }
}
.switchWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

ul.tabButtons {
    display: flex;
    justify-content: flex-start;
    gap: $base-gap * 0.5;
    list-style: none;
    margin-bottom: $base-gap;
    margin-inline: 0;
    flex-direction: column;

    @media (min-width: $screen-sm-min) {
        flex-direction: row;
    }

    li {
        border-bottom-width: 0px;
        font-family: $font-default;
        font-size: $font-size-default-small;
        font-weight: 400;
        line-height: $line-height-default;
        padding-block-end: 0;
         a {
            text-decoration: none;
         }
    }

    @media (min-width: $screen-sm-min) {
        justify-content: flex-end;
    }
}

.toggleButton {
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: currentColor;
    margin-right: 0;
    padding: $base-gap * 0.5 $base-gap * 0.75;
    background-color: #ebebeb !important;

    &.active {
        color: white!important;
        background-color: #006397 !important;

        &:hover {
            cursor: default;
            text-decoration: none;
        }
    }

    &:hover:not(.active) {
      cursor: pointer;
      background: #dbdbdb !important;
    }
}
