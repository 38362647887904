@import "../../assets/stylesheets/variables";

.flexContainer,
div.flexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: $base-gap $base-gap * 1.5;
  padding-inline-start: 0;

  &:hover {
    cursor: pointer;
    &:before {
      opacity: 1;
    }
  }
}

.accordionItem {
  font-family: $font-default;
  :global(.accordion__button[aria-expanded=true]) svg{
    transform: rotate(180deg);
  }

  .iconWrapper {
    border-radius: $border-radius-sm;
    padding: $base-gap * 0.75;
    margin-inline-end: $base-gap;

    &.schule svg, &.kooperationsverbund svg, &.einrichtung svg{
      transform: rotate(0deg) !important;
    }
    &.schule {
      background-color: $color-schule;
    }
    &.kooperationsverbund {
      background-color: $color-kooperationsverbund;
    }
    &.einrichtung {
      background-color: $color-einrichtung;
    }
  }
}

.placeItem {
  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }
}

$iconWidth:  $base-gap * 3.5;

.categoryListWrapper {
  list-style: none;
  padding: 0;
}

.trigger {
  display: block;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-top: $base-gap;
  width: 100%;
}

.locationListItem, div.locationListItem {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  background-color: #ebebeb;
  border: 0;
  padding: $base-gap $base-gap * 1.5 $base-gap $base-gap * 1.25;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: $base-gap;

  &:hover {
    cursor: pointer;
  }
}

.arrow {
  margin-left: auto;
  
  svg {
    transition: .25s;
  }
}

.colorPill {
  height: 1.5em;
  width: 1.5em;
  border: 1px solid rgba(0,0,0,.25);
  margin-right: $base-gap * 0.75;
  border-radius: 500px;
}

.contentContainer {
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  color: $text-color;
  .title {
    font-weight: 700;
    font-size: $font-size-default-large;
    line-height: 24px;
    margin-block-end: $base-gap * 0.25;
    // text-decoration: solid underline 2px;
    // text-underline-offset: 3px;

    &:hover {
      color: $headline-color;
    }    
  }
  
  .description {
    font-weight: 400;
    font-size: $font-size-default-small;
    line-height: 18px;
  }
}
.headline {
  font-family: $font-default;
  font-weight: 600;
  font-size: 30px;
  line-height: 50px;
  color: $headline-color;
}
